










import { Component, Vue } from 'vue-property-decorator';
import AuditLogsDetailsDialog from './audit-logs-details-dialog.vue';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import TableNavigation from '@/components/tables/navigation.vue';

const schema: ITableColumn[] = [
  { label_i18n: 'user', component: TableComponentNames.UserProfileLink, 'min-width': 128 },
  { label_i18n: 'request_ip', prop: 'request_ip', width: 120 },
  { label_i18n: 'device_uid', prop: 'device_uid', 'min-width': 300 },
  { label_i18n: 'action_type', prop: 'action_type', component: TableComponentNames.ActionTypeLabel, width: 200 },
  { label_i18n: 'object_type', prop: 'object_type', component: TableComponentNames.ObjectTypeLabel, 'min-width': 128 },
  { label_i18n: 'object_id', component: TableComponentNames.ObjectItemLink, width: 120 },
  { label_i18n: 'created_date', prop: 'created_date', component: TableComponentNames.DateTime, width: 160 }
];

@Component({
  components: {
    AuditLogsDetailsDialog,
    TableGenerator,
    TableNavigation,
    PageLayout
  }
})
export default class AuditLogsPage extends Vue {
  selected = null;

  get schema(): ITableColumn[] {
    return schema;
  }

  get state() {
    return this.$store.state.audit_logs;
  }

  closeDetailHandler() {
    this.selected = null;
  }

  rowClickHandler({ item }) {
    this.selected = item;
  }
}
